import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { editReader, getReaderData } from '../../api/axios';
import "./edit.css";
import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';

const EditReader = () => {
  const { t } = useTranslation('global');

  const { id } = useParams();
  const navigate = useNavigate();
  const [reader, setReader] = useState({ id:id, name: '', serial_num: '', active: false });

const fetchReaderData = async () => {
    const response = await getReaderData(id);
    const readerData = {
      ...response,
      active: response.active == 1
    };
    setReader(readerData);
};

  useEffect(() => {
    fetchReaderData();
  }, [id]);

  const handleSave = async () => {
    const response = await editReader(reader);
    if(response.success){
      navigate('/readers');
    }
  };

  return (
    <Container sx={{mt: 4}} maxWidth="sm">
      <Box sx={{backgroundColor:"white", p: 4, borderRadius: 3}}>
        <h1>{t("edit.editReader")}</h1>
        <form>
          <label className='edit-label'>
          {t("edit.name")}
            <input
              type="text"
              value={reader.name}
              onChange={(e) => setReader({ ...reader, name: e.target.value })}
            />
          </label>
          <label className='edit-label'>
          {t("edit.serialNumber")}
            <input
              type="text"
              value={reader.serial_num}
              onChange={(e) => setReader({ ...reader, serial_num: e.target.value })}
            />
          </label>
          <div className='checkbox-container'>
            <input
              type="checkbox"
              checked={reader.active}
              onChange={(e) => setReader({ ...reader, active: e.target.checked })}
            />
            <label>{t("edit.active")}</label>
          </div>
          <div className='button-container'>
            <button type="button" className='reader-button' onClick={handleSave}>{t("edit.save")}</button>
            <button type="button" className='reader-button cancel-button' onClick={() => navigate('/readers')}>{t("edit.cancel")}</button>
          </div>
        </form>
      </Box>
    </Container>
  );
};

export default EditReader;
