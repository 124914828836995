import React, { useEffect, useState } from 'react';
import { searchUsersWithTickets } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SearchUsers = () => {
  const { t } = useTranslation('global');
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState({});
  const location = useLocation();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const params = new URLSearchParams(location.search);

        const response = await searchUsersWithTickets(params);
        setUsers(response.data);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(t('search.noResults'));
      }
    };

    fetchUsers();
  }, [location, t]);

  const toggleExpand = (userId) => {
    setExpanded((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  return (
    <Container maxWidth="lg" sx={{mt: 4}}>
      <Typography variant="h4" gutterBottom>
        {t('search.searchResults')}
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {users.map((user) => (
        <Card key={user.id} style={{ marginBottom: '16px' }}>
          <CardHeader
            title={`${user.name} ${user.surname}`}
            subheader={user.phone}
            action={
              <IconButton onClick={() => toggleExpand(user.id)}>
                {expanded[user.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
          />
          <Collapse in={expanded[user.id]} timeout="auto" unmountOnExit>
            <CardContent>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('search.active')}</TableCell>
                      <TableCell>{t('search.changeTime')}</TableCell>
                      <TableCell>{t('search.createTime')}</TableCell>
                      <TableCell>{t('search.dailyNo')}</TableCell>
                      <TableCell>{t('search.dayNo')}</TableCell>
                      <TableCell>{t('search.hFrom')}</TableCell>
                      <TableCell>{t('search.hTo')}</TableCell>
                      <TableCell>{t('search.updateTime')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {user.tickets.map((ticket) => (
                      <TableRow key={ticket.ticket_id}>
                        <TableCell>{(ticket.active === '0')?t('search.no'):t('search.yes')}</TableCell>
                        <TableCell>{ticket.change_time}</TableCell>
                        <TableCell>{ticket.create_time}</TableCell>
                        <TableCell>{ticket.daily_no}</TableCell>
                        <TableCell>{ticket.day_no}</TableCell>
                        <TableCell>{ticket.h_from}</TableCell>
                        <TableCell>{ticket.h_to}</TableCell>
                        <TableCell>{ticket.update_time}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </Container>
  );
};

export default SearchUsers;


