export const baseURL = `${window.location.protocol}//${window.location.host}/ticketing/back-app`;
//export const baseURL = process.env.REACT_APP_API_URL;
export const downloadTicketsAppURL = '';

// src/constants/permissions.js

export const PERMISSIONS = {
    VIEW_HOME: '1_/home',
    VIEW_ACTIVE: '2_/active',
    VIEW_PASSED: '3_/passed',
    VIEW_NONACTIVE: '4_/nonactive',
    VIEW_SOLD: '5_/sold',
    VIEW_CATEGORY: '6_/category',
    VIEW_TYPE: '7_/type',
    VIEW_KIND: '8_/kind',
    VIEW_LOG_CARD: '9_/log_card',
    VIEW_PAYMENT_METHOD: '10_/payment_method',
    VIEW_MONITORING: '11_/monitoring',
    CREATE_NEW_READER: '12_/new-reader',
    CREATE_NEW_TICKET: '13_/new-ticket',
    CREATE_NEW_USER: '14_/new-user',
    VIEW_DAILYREP: '15_/dailyrep',
    VIEW_TICKET_INFO: '16_/ticket-info',
    VIEW_ACCESS_RIGHTS: '17_/access',
    VIEW_LIST_READERS: '18_/readers',
    EDIT_TICKET: '19_/ticket/edit',
    EDIT_READER: '20_/readers/edit',
    VIEW_PRICELIST: '21_/price-list',
    EDIT_PRICELIST: '22_/price-list/edit',
    CREATE_NEW_ITEM: '23_/new-item',
    DELETE_ITEM: '24_/price-list/delete',
    CREATE_NEW_CATEGORY: '25_/new-category',
    CREATE_NEW_KIND: '26_/new-kind',
    VIEW_SEARCH_USERS: '27_/search-users',
    VIEW_PURCHASING: '28_/purchasing'
};


export const PERMISSION_ENDPOINT = baseURL+'/perm/manage';
