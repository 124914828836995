import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { TextField, Button, Checkbox, Container, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, FormGroup, Typography, Grid, Box } from '@mui/material';
import { getReaders, postRequest } from '../../api/axios';
import Error from '../Error/Error';
import Success from '../Success/Success';
import Notification from '../Notification/Notification'; // Import Notification component
import { getUsername } from '../../context/AuthFunc';
import { useTranslation } from 'react-i18next';
import "./newTicket.css";
import QRGenerateTicket from './QRGenerateTicket';

const NewTicket = () => {
    const { t } = useTranslation('global');

    const [ticketId, setTicketId] = useState('');
    const [surname, setSurname] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [hFrom, setHFrom] = useState('07:00');
    const [hTo, setHTo] = useState('23:00');
    const [dayNo, setDayNo] = useState('30');
    const [entryNo, setEntryNo] = useState('30');
    const [dailyNo, setDailyNo] = useState('0');
    const [readers, setReaders] = useState([]);
    const [submitter, setSubmitter] = useState('');
    const [selectedReaders, setSelectedReaders] = useState([]);
    const [changeTime, setChangeTime] = useState(() => {
        const today = new Date().toISOString().split('T')[0];
        return `${today} 00:00:00`;
    });
    const [errMsg, setErrMsg] = useState('');
    const [succ, setSucc] = useState('');
    const [showNotification, setShowNotification] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchReaders = async () => {
            try {
                const response = await getReaders();
                setReaders(response); // Assuming the response contains a list of readers
                setSelectedReaders(response.map(reader => reader.id));
            } catch (error) {
                console.error(t("newTicket.errorFetchingReaders"), error);
            }
        };

        fetchReaders();
        setSubmitter(getUsername);
    }, []);
    
    const formatTime = (time) => {
        if (time.length === 5) { // Format H:i
            return `${time}:00`;
        }
        return time;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formattedHFrom = formatTime(hFrom);
        const formattedHTo = formatTime(hTo);
        const isValidDateTime = (value) => {
            const date = new Date(value.replace(' ', 'T')); // Zamenite razmak sa 'T' za ISO standard
            return !isNaN(date.getTime());
        };
        if (!ticketId) {
            setErrMsg(t("newTicket.ticketNumberRequired"));
            return;
        } 
        if (!isValidDateTime(changeTime)) {
            setErrMsg(t("newTicket.invalidChangeTimeFormat"));
            return;
        }
        if (selectedReaders.length === 0) {
            setSelectedReaders(readers.map(reader => reader.id));
        }

        const newTicket = {
            ticket_id: ticketId,
            surname,
            name,
            phone,
            h_from: formattedHFrom,
            h_to: formattedHTo,
            day_no: dayNo,
            entry_no: entryNo,
            daily_no: dailyNo,
            readers: selectedReaders.includes('all') ? readers.map(reader => reader.id) : selectedReaders,
            submitter: submitter,
            change_time: changeTime,
            create_time: new Date().toISOString().replace('T', ' ').split('.')[0],
        };

        try {
            const response = await postRequest('/write-qr', new URLSearchParams(newTicket));
            if (!response.success) {
                setErrMsg(t("newTicket.somethingWentWrong"));
                setTimeout(() => setErrMsg(''), 3000);
            } else {
                setSucc(t("newTicket.successfullyAdded"));
                setTicketId('');
                setSurname('');
                setName('');
                setPhone('');
                setHFrom('07:00');
                setHTo('23:00');
                setDayNo('30');
                setEntryNo('30');
                setDailyNo('0');
                setSelectedReaders(readers.map(reader => reader.id));
                setTimeout(() => setSucc(''), 3000);
            }
            setShowNotification(true);
        } catch (error) {
            setErrMsg(t("newTicket.failedToAddTicket"));
            setShowNotification(true);
        }
    };

    const handleReaderChange = (e) => {
        const value = e.target.value;
        if (value === 'all') {
            setSelectedReaders((prev) =>
                prev.includes('all') ? [] : readers.map(reader => reader.id)
            );
        } else {
            setSelectedReaders((prev) =>
                prev.includes(value)
                    ? prev.filter((reader) => reader !== value)
                    : [...prev.filter((reader) => reader !== 'all'), value]
            );
        }
    };

    return (
    <Container maxWidth="sm" className="container-ticket" sx={{ p: 3, flexShrink: 0 , mt: 4}} >
        <Typography variant="h4" sx={{ mb:4 }}>{t("newTicket.newPlasticTicket")}</Typography>
            <form onSubmit={handleSubmit} style={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <TextField
                    label={t("newTicket.ticketSerialNumber")}
                    fullWidth
                    value={ticketId}
                    onChange={(e) => setTicketId(e.target.value)}
                    required
                    title={t("newTicket.readFromRFID")}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t("newTicket.firstName")}
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label={t("newTicket.lastName")}
                    fullWidth
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    label={t("newTicket.phoneNumber")}
                    fullWidth
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="time"
                    label={t("newTicket.entryFrom")}
                    fullWidth
                    value={hFrom}
                    onChange={(e) => setHFrom(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="time"
                    label={t("newTicket.entryUntil")}
                    fullWidth
                    value={hTo}
                    onChange={(e) => setHTo(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    type="datetime"
                    label={t("newTicket.changeTime")}
                    fullWidth
                    value={changeTime}
                    onChange={(e) => setChangeTime(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={t("newTicket.numberOfDaysTheTicketIsValid")}
                    fullWidth
                    value={dayNo}
                    onChange={(e) => setDayNo(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={t("newTicket.totalNumberOfEntries")}
                    fullWidth
                    value={entryNo}
                    onChange={(e) => setEntryNo(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={t("newTicket.numberOfDailyEntries")}
                    fullWidth
                    value={dailyNo}
                    onChange={(e) => setDailyNo(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
                    {t("newTicket.selectReader")}
                </Button>
            </Grid>
        

        {/* Modal za izbor čitača */}
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} fullWidth maxWidth="sm">
            <DialogTitle>{t("newTicket.selectReader")}</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedReaders.length === readers.length}
                            onChange={handleReaderChange}
                            value="all"
                        />
                    }
                    label={t("newTicket.all")}
                />
                <Grid container spacing={1}>
                    {readers.map((reader) => (
                        <Grid item xs={12} sm={6} key={reader.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedReaders.includes(reader.id)}
                                        onChange={handleReaderChange}
                                        value={reader.id}
                                    />
                                }
                                label={reader.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setIsModalOpen(false)} variant="contained" color="primary">
                    {t("newTicket.ok")}
                </Button>
            </DialogActions>
        </Dialog>
                    <Box item xs={12} sx={{ width:"100%", display:"flex", justifyContent:"flex-end"}}>
                                <Button sx={{ width:"50%"}} variant="contained" type="submit">{t("newTicket.add")}</Button>
                            </Box>
                    </Grid>
    </form>

    {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
    {succ && <Success message={succ} onClose={() => setSucc('')} />}
    </Container>
    );
};

export default NewTicket;
