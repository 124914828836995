import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Typography } from '@mui/material';
import { addCategory } from '../../api/axios';

const NewCategory = () => {
    const { t } = useTranslation("global");
    const [categoryName, setCategoryName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async () => {
        if (!categoryName.trim()) {
            setErrorMessage(t('priceList.PleaseEnterACategoryName'));
            return;
        }

        try {
            const param = new URLSearchParams();
            param.append("category_name", categoryName);
            await addCategory(param);
            setSuccessMessage(t('priceList.CategoryAddedSuccessfully'));
            setErrorMessage('');
            setCategoryName(''); // Reset the input field
        } catch (error) {
            setErrorMessage(t('priceList.FailedToAddCategory'));
            setSuccessMessage('');
        }
    };

    const handleChange = (event) => {
        const input = event.target.value.toUpperCase(); // Convert input to uppercase
        setCategoryName(input);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                maxWidth: 400,
                margin: '0 auto',
                mt: 4,
                backgroundColor: "white",
                p: 5,
                borderRadius: 3
            }}
        >
            <Typography variant="h5" textAlign="center">
                {t('priceList.AddNewCategory')}
            </Typography>
            <TextField
                label={t('priceList.CategoryName')}
                value={categoryName}
                onChange={handleChange}
                fullWidth
                error={!!errorMessage}
                helperText={errorMessage || ''}
                inputProps={{ style: { textTransform: 'uppercase' } }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
            >
                {t("priceList.Submit")}
            </Button>
            {successMessage && (
                <Typography variant="body1" color="success.main" textAlign="center">
                    {successMessage}
                </Typography>
            )}
        </Box>
    );
};

export default NewCategory;

