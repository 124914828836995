import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Typography } from '@mui/material';
import { changeUserPassword } from '../../api/axios';
import "./editProfile.css";
import { getUsername } from '../../context/AuthFunc';


const ChangePassword = () => {
  const { t } = useTranslation('global');
  
  // Stanje za unos podataka
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Handler funkcija za promenu vrednosti inputa
  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  // Funkcija za slanje zahteva za promenu lozinke
  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      alert(t('changePassword.passwordsDoNotMatch')); // Ako se nova i potvrđena lozinka ne slažu
      return;
    }
  
    // Dobijanje korisničkog imena
    const username = getUsername();
  
    // Kreiranje parametara za promenu lozinke
    const params = new URLSearchParams({
        username: username,
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
  
    try {
      const response = await changeUserPassword(params); // Poziv funkcije za promenu lozinke
      if (response.success) {
        alert(t('changePassword.passwordChangedSuccessfully')); // Uspešna promena lozinke
      } else {
        alert(t('changePassword.failedToChangePassword')); // Greška pri promeni lozinke
      }
    } catch (error) {
      console.error(error);
      alert(t('changePassword.errorOccurred')); // Greška prilikom slanja zahteva
    }
  };
  

  return (
    <Box sx={{
        mt: 4,
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: '#f9f9f9',
        maxWidth: 500
    }}
    className="container-user">
      <Typography variant="h4" gutterBottom>
        {t('changePassword.changePassword')}
      </Typography>
      
      <TextField
        label={t('changePassword.oldPassword')}
        type="password"
        value={oldPassword}
        fullWidth
        onChange={handleOldPasswordChange}
        sx={{ marginBottom: 2 }}
      />
      
      <TextField
        label={t('changePassword.newPassword')}
        type="password"
        value={newPassword}
        fullWidth
        onChange={handleNewPasswordChange}
        sx={{ marginBottom: 2 }}
      />
      
      <TextField
        label={t('changePassword.confirmNewPassword')}
        type="password"
        value={confirmPassword}
        fullWidth
        onChange={handleConfirmPasswordChange}
        sx={{ marginBottom: 2 }}
      />
      
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        {t('changePassword.changePassword')}
      </Button>
    </Box>
  );
};

export default ChangePassword;
