import React, { useEffect, useState } from 'react'
import { getPriceList } from '../../api/axios'
import DataTable from '../Table/DataTable';
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material';

const PriceList = () => {
    const { t } = useTranslation('global');

    const [priceList, setPriceList] = useState([]);
    const [loading, setLoading] = useState(true);
  
    const fetchPriceList = async() =>{
      const response = await getPriceList();
      setPriceList(response);
      console.log(response);
      setLoading(false);
    };
    useEffect(() => {
      fetchPriceList();
    }, []);
  
    const columns = [
      { Header: t("priceList.category"), accessor: 'category' },
      { Header: t("priceList.type"), accessor: 'type' },
      { Header: t("priceList.kind"), accessor: 'kind' },
      { Header: t("priceList.price"), accessor: 'price' },
      { Header: t("priceList.discount"), accessor: 'discount' },
      { Header: t("priceList.VAT"), accessor: 'vat' },
      { Header: t("priceList.description"), accessor: 'description' },
      { Header: t("priceList.user"), accessor: 'user_info' },
    ];
  
    if (loading) {
      return <p>{t("priceList.loading")}</p>;
    }
  
    return (
      <Container sx={{ mt:5 }}>
        <DataTable initialData={priceList} columns={columns} entityName="item" h2={t("priceList.priceList")} />
      </Container>
    );
}

export default PriceList
//id, category, type, kind, price, vat, description, user_log as user_info, discount