import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Typography, InputLabel, Select, MenuItem, FormControl} from '@mui/material';
import { addKind, getTypes } from '../../api/axios';

const NewKind = () => {
    const { t } = useTranslation("global");
    const [types, setTypes] = useState([]);
    const [formData, setFormData] = useState({
        kind_name: '',
        day_no: '1',
        entry_no: '1',
        daily_no: '1',
        time_from: '00:00:00',
        time_to: '23:59:59',
        type_id: '',
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        const typesData = await getTypes();
        setTypes(typesData);
        console.log(types);
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleTypeChange = (e) => {
        const selectedTypeId = e.target.value;
        setFormData({ ...formData, type_id: selectedTypeId });
    };

    const handleSubmit = async () => {
        const { kind_name, day_no, entry_no, daily_no, time_from, time_to, type_id } = formData;

        if (!kind_name || !day_no || !entry_no || !daily_no || !time_from || !time_to || !type_id) {
            setErrorMessage(t('priceList.PleaseFillInAllRequiredFields'));
            return;
        }

        if (isNaN(day_no) || isNaN(entry_no) || isNaN(daily_no) || day_no <= 0 || entry_no <= 0 || daily_no <= 0) {
            setErrorMessage(t('priceList.DayEntryDailyPositiveIntegers'));
            return;
        }

        try {
            const params = new URLSearchParams();
            params.append('kind_name', kind_name);
            params.append('day_no', day_no);
            params.append('entry_no', entry_no);
            params.append('daily_no', daily_no);
            params.append('time_from', time_from);
            params.append('time_to', time_to);
            params.append('type', type_id);
            params.append('templates', 'NULL');

            await addKind(params);
            setSuccessMessage(t('priceList.KindAddedSuccessfully'));
            setErrorMessage('');
            setFormData({
                kind_name: '',
                day_no: '',
                entry_no: '',
                daily_no: '',
                time_from: '',
                time_to: '',
                type_id: '',
            });
        } catch (error) {
            setErrorMessage(t('priceList.FailedToAddKind'));
            setSuccessMessage('');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                maxWidth: 600,
                margin: '0 auto',
                mt: 4,
                backgroundColor: 'white',
                p: 5,
                borderRadius: 3,
            }}
        >
            <Typography variant="h5" textAlign="center">
                {t('priceList.AddNewKind')}
            </Typography>

            <TextField
                label={t('priceList.KindName')}
                name="kind_name"
                value={formData.kind_name}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label={t('priceList.DayNo')}
                name="day_no"
                value={formData.day_no}
                onChange={handleChange}
                fullWidth
                type="number"
            />
            <TextField
                label={t('priceList.EntryNo')}
                name="entry_no"
                value={formData.entry_no}
                onChange={handleChange}
                fullWidth
                type="number"
            />
            <TextField
                label={t('priceList.DailyNo')}
                name="daily_no"
                value={formData.daily_no}
                onChange={handleChange}
                fullWidth
                type="number"
            />
            <TextField
                label={t('priceList.TimeFrom')}
                name="time_from"
                value={formData.time_from}
                onChange={handleChange}
                fullWidth
                type="time"
            />
            <TextField
                label={t('priceList.TimeTo')}
                name="time_to"
                value={formData.time_to}
                onChange={handleChange}
                fullWidth
                type="time"
            />
            <Box item xs={12} sm={6}>
            <FormControl fullWidth required>
            <InputLabel>{t("priceList.type")}</InputLabel>
            <Select
                name="type_id"
                value={formData.type_id}
                onChange={handleTypeChange}>
                {types.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                    {type.name}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
        </Box>
            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
                {t('priceList.Submit')}
            </Button>
            {errorMessage && (
                <Typography variant="body2" color="error" textAlign="center">
                    {errorMessage}
                </Typography>
            )}
            {successMessage && (
                <Typography variant="body2" color="success.main" textAlign="center">
                    {successMessage}
                </Typography>
            )}
        </Box>
    );
};

export default NewKind;
