import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '../Table/DateRangePicker';
import ChartComponent from './ChartComponent';
import { getReportOptions, getSoldTickets } from '../../api/axios';
import './reportselection.css';
import DailyReport from '../Reports/DailyReport';
import { useTranslation } from 'react-i18next';
import { Button, Box, TextField, ListSubheader, MenuItem, Select, InputLabel, FormControl, Typography, Grid } from '@mui/material';

const ReportSelection = () => {
  const { t } = useTranslation('global');

  const [reportType, setReportType] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [dateError, setDateError] = useState('');
  const navigate = useNavigate();
  const [reportCategoryOptions, setReportCategoryOptions] = useState([]);
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [reportKindOptions, setReportKindOptions] = useState([]);
  const [reportLogCardOptions, setReportLogCardOptions] = useState([]);
  const [reportPaymentOptions, setReportPaymentOptions] = useState([]);
  const [category, setCategory] = useState('category');
  const [type, setType] = useState('type');
  const [kind, setKind] = useState('kind');
  const [logCard, setLogCard] = useState('log_card');
  const [paymentMethod, setPaymentMethod] = useState('payment_method');
  const [ticket_id, setTicketID] = useState('');
  const [searchName, setSearchName] = useState('');
  const [searchSurname, setSearchSurname] = useState('');
  const [searchPhone, setSearchPhone] = useState('');
  const [chartData, setChartData] = useState({ labels: [], counts: [] });

  // const [, ] = useState('');

  useEffect(() => {

    const fetchReportOptions = async () => {
      try {

        const responses = await getReportOptions(category);
        const categoryOptions = responses;
        //console.log("CATEGORY OPTIONS: " + categoryOptions);
        setReportCategoryOptions(categoryOptions);

        const responses1 = await getReportOptions(type);
        const typeOptions = responses1;
        //console.log("TYPE OPTIONS: " + typeOptions);
        setReportTypeOptions(typeOptions);

        const responses2 = await getReportOptions(kind);
        const kindOptions = responses2;
        //console.log("KIND OPTIONS: " + kindOptions);
        setReportKindOptions(kindOptions);

        const responses3 = await getReportOptions(logCard);
        const logOptions = responses3;
        //console.log("LOG OPTIONS: " + logOptions);
        setReportLogCardOptions(logOptions);

        const responses4 = await getReportOptions(paymentMethod);
        const paymentOptions = responses4;
        //console.log("PAYMENT OPTIONS: " + paymentOptions);
        setReportPaymentOptions(paymentOptions);

      } catch (error) {
        console.error(t("reportSelection.errorFetchingReportOptions"), error);
      }
    };

    fetchReportOptions();
  }, []);

  useEffect(() => {
  const fetchChartData = async () => {
    try {

      const chartEndDate = new Date(Date.now() - 0 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]; // Današnji datum
      const chartStartDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]; // Datum pre 30 dana

      const response = await getSoldTickets(chartStartDate, chartEndDate);
      console.log(response);

      // Grupisanje podataka po datumima
      const groupedData = response.reduce((acc, item) => {
        const date = item.date;
        acc[date] = (acc[date] || 0) + 1;
        return acc;
      }, {});

      // Priprema podataka za grafikon
      const dates = [];
      const counts = [];
      for (let date = new Date(chartStartDate); date <= new Date(chartEndDate); date.setDate(date.getDate() + 1)) {
        const formattedDate = date.toISOString().split('T')[0];
        dates.push(formattedDate);
        counts.push(groupedData[formattedDate] || 0);
      }

      setChartData({ labels: dates, counts });

    } catch (error) {
      console.error(t("reportSelection.errorFetchingChartData"), error);
    }
  };

  fetchChartData();
}, []);

  const handleDateRangeChange = (start, end, error) => {
    setStartDate(start);
    setEndDate(end);
    setDateError(error);
  };

  const handleReportChange = (event) => {
    const [route, option] = event.target.value.split('-');
    setReportType(route);
    setSelectedOption(option);
    //console.log(reportType, selectedOption);
  };

  useEffect(() => {
    if (reportType && startDate && endDate && !dateError) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [reportType, startDate, endDate, dateError]);

  const handleSubmit = () => {
    if (reportType && startDate && endDate) {
      navigate(`/${reportType}`, { state: { startDate, endDate, selectedOption } });
    } else {
      alert(t("reportSelection.pleaseSelectAReportAndDateRange"));
    }
  };

  const handleIDSearch = () => {
    if (ticket_id) {
      const newTabUrl = `/ticket-info?ticket_id=${ticket_id}`;
      window.open(newTabUrl, '_blank');
    }
  };
  const handleEditTicket = () => {
    if (ticket_id) {
      navigate(`/ticket/edit/${ticket_id}`);
    }
  };

  const handleUserSearch = () => {
    const newTabUrl = `/search-users?name=${searchName}&surname=${searchSurname}&phone=${searchPhone}`;
    window.open(newTabUrl, '_blank');  }

  return (
  <Grid container sx={{m:0, p:0, width:"100vw", height:"100vh"}} className='container-main'>
    {/* Part 1 */}
    <Grid xs={12} sm={4} md={4} lg={4} xl={4} p={2} item >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center", backgroundColor: "white",
      borderRadius: 2,
      }} p={2} height="100%">
        <Typography variant="h6">{t("reportSelection.searchByTicketNumber")}</Typography>
        <TextField
          id="ticketId"
          variant="outlined"
          placeholder={t("reportSelection.ticketNumber")}
          value={ticket_id}
          onChange={(e) => setTicketID(e.target.value)}
          size= "small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleIDSearch}
        >
          {t("reportSelection.search")}
        </Button>

        <Typography variant="h6">{t("reportSelection.changeTicketData")}</Typography>
        <TextField
          id="ticketId"
          variant="outlined"
          placeholder={t("reportSelection.ticketNumber")}
          value={ticket_id}
          onChange={(e) => setTicketID(e.target.value)}
          size= "small"

        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleEditTicket}
        >
          {t("reportSelection.search")}
        </Button>
      </Box>
    
</Grid>


    {/* Part 2 */}
    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}
      p={2}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 3,
        backgroundColor: "white",
        borderRadius: 2,
      }} p={2} height="100%">
      <Typography variant="h6">{t("reportSelection.selectReportAndDate")}</Typography>

      <FormControl fullWidth variant="outlined" sx={{ maxWidth: "400px" }} size="small">
  <InputLabel>{t("reportSelection.selectReport")}</InputLabel>
  <Select
    label={t("reportSelection.selectReport")}
    onChange={handleReportChange}
  >
    <MenuItem value="">{t("reportSelection.selectReport")}</MenuItem>
    <MenuItem value="dailyrep">{t("reportSelection.dailyReport")}</MenuItem>
    <MenuItem value="active">{t("reportSelection.activeTickets")}</MenuItem>
    <MenuItem value="passed">{t("reportSelection.passedTickets")}</MenuItem>
    <MenuItem value="succ_passed">{t("reportSelection.successfullyPassedTickets")}</MenuItem>
    <MenuItem value="nonactive">{t("reportSelection.inactiveTickets")}</MenuItem>
    <MenuItem value="sold">{t("reportSelection.soldTickets")}</MenuItem>

    <ListSubheader>{t("reportSelection.category")}</ListSubheader>
    {Array.isArray(reportCategoryOptions) &&
      reportCategoryOptions.map((option, index) => (
        <MenuItem key={option + `_${index}`} value={category + `-${option}`}>
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </MenuItem>
      ))}

    <ListSubheader>{t("reportSelection.type")}</ListSubheader>
    {Array.isArray(reportTypeOptions) &&
      reportTypeOptions.map((option, index) => (
        <MenuItem key={option + `_${index}`} value={type + `-${option}`}>
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </MenuItem>
      ))}

    <ListSubheader>{t("reportSelection.kind")}</ListSubheader>
    {Array.isArray(reportKindOptions) &&
      reportKindOptions.map((option, index) => (
        <MenuItem key={option + `_${index}`} value={kind + `-${option}`}>
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </MenuItem>
      ))}

    <ListSubheader>{t("reportSelection.logCard")}</ListSubheader>
    {Array.isArray(reportLogCardOptions) &&
      reportLogCardOptions.map((option, index) => (
        <MenuItem key={option + `_${index}`} value={logCard + `-${option}`}>
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </MenuItem>
      ))}

    <ListSubheader>{t("reportSelection.paymentMethod")}</ListSubheader>
    {Array.isArray(reportPaymentOptions) &&
      reportPaymentOptions.map((option, index) => (
        <MenuItem key={option + `_${index}`} value={paymentMethod + `-${option}`}>
          {option.charAt(0).toUpperCase() + option.slice(1)}
        </MenuItem>
      ))}
  </Select>
</FormControl>


    <Box sx={{ width: "100%", maxWidth: "400px" }}>
      <DateRangePicker onDateRangeChange={handleDateRangeChange} />
    </Box>

    <Button
      variant="contained"
      color="primary"
      onClick={handleSubmit}
      disabled={!isButtonEnabled}
    >
      {t("reportSelection.generateReport")}
    </Button>
  </Box>
 
</Grid>


    {/* Part 3 */}
    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}
  p={2}
>
  <Box sx={{
    display: "flex",
    flexDirection: "column", // Organizuje elemente vertikalno
    alignItems: "center", // Centriranje horizontalno
    gap: 2,
    backgroundColor: "white",
    borderRadius: 2
  }} p={2} height="100%">
  <Typography variant="h6" title={t("reportSelection.fillAnyField")}>
    {t("reportSelection.searchByUser")}
  </Typography>
  <TextField
    id="name"
    onChange={(e) => setSearchName(e.target.value)}
    variant="outlined"
    placeholder={t("reportSelection.name")}
    sx={{ maxWidth: "400px" }} // Ograničenje širine za bolji izgled
    size= "small"
  />
  <TextField
    id="surname"
    onChange={(e) => setSearchSurname(e.target.value)}
    variant="outlined"
    placeholder={t("reportSelection.lastName")}
    sx={{ maxWidth: "400px" }}
    size= "small"
  />
  <TextField
    id="phone"
    onChange={(e) => setSearchPhone(e.target.value)}
    variant="outlined"
    placeholder={t("reportSelection.phoneNumber")}
    sx={{ maxWidth: "400px" }}
    size= "small"
    font= "small"
  />
  <Button
    variant="contained"
    onClick={handleUserSearch}
    color="primary"
    sx={{ width: "40%", mt: 2 }} // Dodaj razmak gore
  >
    {t("reportSelection.search")}
  </Button>
  </Box>
  
</Grid>


    {/* Part 4*/}
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={2}>
      
      <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center", 
      backgroundColor: "white",
      borderRadius: 2,
      }}>
        <Box sx={{overflow: 'hidden', mt: 2}}>
          <Typography variant='h6'>
            {t("reportSelection.soldTickets")}
          </Typography> 
        </Box> 
        <ChartComponent data={chartData} />
      </Box>
    </Grid>
    </Grid>
);
};

export default ReportSelection;
