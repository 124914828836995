import axios from "axios";
import { baseURL } from "../constants/const";

const instance = axios.create({
  baseURL: baseURL,
});

export const getActiveTickets = async (startDate, endDate) => {
  try {
    const response = await instance.get('/active-tickets', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching active tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getNonActiveTickets = async (startDate, endDate) => {
  try {
    const response = await instance.get('/nonactive-tickets', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching nonactive tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getMonitoring = async (startDate, endDate) => {
  try {
    const response = await instance.get('/monitoring', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching monitoring:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getPassedTickets = async (startDate, endDate) => {
  try {
    const response = await instance.get('/passed', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching passed tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getSuccPassedTickets = async (startDate, endDate) => {
  try {
    const response = await instance.get('/passed-succ', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching passed tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getSoldTickets = async (startDate, endDate) => {
  try {
    const response = await instance.get('/sold-tickets', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching sold tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getReportOptions = async (param) => {
  try {
    //console.log(param);
    const response = await instance.get('/report-options', {
      params: { param }
    });
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error fetching report options:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getCategoryTickets = async (startDate, endDate, selectedOption) => {
  try {
    const response = await instance.get('/sold-category', {
      params: { startDate, endDate, selectedOption },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching cateory tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getTypeTickets = async (startDate, endDate, selectedOption) => {
  try {
    const response = await instance.get('/sold-type', {
      params: { startDate, endDate, selectedOption },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching type tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getKindTickets = async (startDate, endDate, selectedOption) => {
  try {
    const response = await instance.get('/sold-kind', {
      params: { startDate, endDate, selectedOption },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching kind tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getLogTickets = async (startDate, endDate, selectedOption) => {
  try {
    const response = await instance.get('/sold-log', {
      params: { startDate, endDate, selectedOption },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching log tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const getPaymentMethodTickets = async (startDate, endDate, selectedOption) => {
  try {
    const response = await instance.get('/payment-method', {
      params: { startDate, endDate, selectedOption },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching payment method tickets:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getReaderIdExist = async (id) => {
  try {
    const response = await instance.get(`/reader/check-id`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reader exists:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getReaders = async () => {
  try {
    //console.log("get readers");
    const response = await instance.get('/reader/get-readers');
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error fetching readers options:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getReaderData = async (id) => {
  try {
    //console.log("get readers");
    const response = await instance.get('/reader/get-reader', {
      params: { id },
    });
    //console.log(response);
    return response.data;
  } catch (error) {
    console.error('Error fetching reader data options:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getTicketIdExist = async (id) => {
  try {
    const response = await instance.get(`/ticket/check-id`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reader exists:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getUserExist = async (username) => {
  try {
    const response = await instance.get(`/users/check`, {
      params: { username },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user exists:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getDailyReport = async (startDate, endDate) => {
  try {
    const response = await instance.get('/daily-report', {
      params: { startDate, endDate },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching daily report data:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getInfoPerTID = async (ticket_id) => {
  try {
    const response = await instance.get(`/ticket/info-id`, {
      params: { ticket_id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching reader exists:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getUsersExceptLogin = async (username) => {
  try {
    const response = await instance.get(`/perm/users`, {
      params: { username },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getPages = async () => {
  try {
    const response = await instance.get(`/perm/pages`);
    return response.data;
  } catch (error) {
    console.error('Error fetching pages:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getPermisisons = async (user_id) => {
  try {
    const response = await instance.get(`/perm/permissions`, {
      params: { user_id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching permissions:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getTicket = async (id) => {
  try {
    const response = await instance.get(`/get/ticket`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching ticket:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};
export const getPriceList = async () => {
  try {
    const response = await instance.get('/price-list');
    return response.data;
  } catch (error) {
    console.error('Error fetching price list:', error);
    throw error; // Propagirajte grešku kako bi je mogli obraditi u komponenti koja poziva ovu funkciju
  }
};

export const postRequest = async (endpoint, formData) => {
  let ret = { success: false, message: '', other: '' };

  try {
    const response = await instance.post(endpoint, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    console.log(response.data);
    ret.success = response.data.success;
    ret.message = response.data.message || 'Request successful';
    ret.other = response.data.other;
    return ret;
  } catch (error) {
    console.error('Error posting data:', error);
    if (!error.response) {
      console.log('No server response');
      ret.message = 'No server response';
    } else if (error.response.status === 400) {
      console.log('Bad Request');
      ret.message = 'Bad Request';
    } else if (error.response.status === 401) {
      console.log('Unauthorized');
      ret.message = 'Unauthorized';
    } else {
      console.log('Post request failed');
      ret.message = 'Post request failed';
    }
    return ret;
  }
};

export const editReader = async (reader) => {
  let ret = { success: false, message: '' };
  try {
    const response = await instance.put(`/edit/reader`, reader, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
    ret.success = response.data.success;
    ret.message = response.data.message || 'Request successful';
    return ret;
  } catch (error) {
    console.error('Error put data:', error);
    if (!error.response) {
      console.log('No server response');
      ret.message = 'No server response';
    }
    return ret;
  }
};
export const editTicket = async (ticket) => {
  let ret = { success: false, message: '' };
  try {
    const response = await instance.put(`/edit/ticket`, ticket, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
    ret.success = response.data.success;
    ret.message = response.data.message || 'Request successful';
    return ret;
  } catch (error) {
    console.error('Error put data:', error);
    if (!error.response) {
      console.log('No server response');
      ret.message = 'No server response';
    }
    return ret;
  }
};
export const editTicketUser = async (user) => {
  let ret = { success: false, message: '' };
  try {
    const response = await instance.put(`/edit/ticket-user`, user, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });
    ret.success = response.data.success;
    ret.message = response.data.message || 'Request successful';
    return ret;
  } catch (error) {
    console.error('Error put data:', error);
    if (!error.response) {
      console.log('No server response');
      ret.message = 'No server response';
    }
    return ret;
  }
};

export const getTypes = async () => {
  try {
    const response = await instance.get('/get-types');
    return response.data;
  } catch (error) {
    console.error('Error fetching types:', error);
    throw error;
  }
};

export const getKinds = async () => {
  try {
    const response = await instance.get('/get-kinds');
    return response.data;
  } catch (error) {
    console.error('Error fetching kinds:', error);
    throw error;
  }
};

export const getCategories = async () => {
  try {
    const response = await instance.get('/get-categories');
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const addItem = async (params) => {
  try {
    const response = await instance.post('/add-item', params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }
    );
    return response;
  } catch (error) {
    console.error('Error adding item:', error);
    throw error;
  }
};

export const ticketSendEmail = async (formData) => {
  try {
      const response = await instance.post("/send-mail", formData, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      });
      return response;
  } catch (error) {
      console.error("Error in ticketSendEmail:", error);
      throw error;
  }
};

export const changeUserPassword = async (params) => {
  try {
    const response = await instance.post(
      '/change-password', params,
      {   
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const addKind = async (params) => {
  try {
    const response = await instance.post(
      '/add-kind', params,
      {   
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding kind:', error);
    throw error;
  }
};

export const addCategory = async (params) => {
  try {
    const response = await instance.post(
      '/add-category', params,
      {   
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error adding category:', error);
    throw error;
  }
};

export const searchUsersWithTickets = async (params) => {
  try {
    const response = await instance.post(
      '/search-users', params,
      {   
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error searching users:', error);
    throw error;
  }
};

export const deleteItem = async (params) => {
  try {
    const response = await instance.post(
      '/delete-item', params,
      {   
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const deleteReader = async (params) => {
  try {
    const response = await instance.post(
      '/delete-reader', params,
      {   
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting reader:', error);
    throw error;
  }
};

export default instance;