import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Drawer, Box } from '@mui/material';
import { hasPermission } from '../../context/AuthFunc';
import { PERMISSIONS } from '../../constants/const';
import { useTranslation } from 'react-i18next';
import Logout from '../Logout/Logout';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import DownloadApp from '../DownloadAPP/DownloadAPP';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu as MenuIcon } from '@mui/icons-material';


const Navbar = ({ isLoggedIn, setIsLoggedIn }) => {
  const { t } = useTranslation('global');
  const [readersAnchorEl, setReadersAnchorEl] = useState(null);
  const [priceListAnchorEl, setPriceListAnchorEl] = useState(null);
  const [newTicketAnchorEl, setNewTicketAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);



  const handleMenuOpen = (anchorElSetter) => (event) => {
    anchorElSetter(event.currentTarget);
  };

  const handleMenuClose = (anchorElSetter) => () => {
    anchorElSetter(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };



  const renderButtonWithMenu = (permission, menuState, setMenuState, menuItems, label) => {
    if (hasPermission(permission)) {
      const handleMouseOver = (event) => {
        setMenuState(event.currentTarget); // Postavlja dugme kao anchor element za meni
      };
  
      const handleMouseLeave = (event) => {
        const relatedTarget = event.relatedTarget;
      
        if (
          !relatedTarget || // Proverava da li je relatedTarget null ili undefined
          !(relatedTarget instanceof Node) || // Proverava da li je validan DOM Node
          !event.currentTarget.contains(relatedTarget) // Proverava da li currentTarget sadrži relatedTarget
        ) {
          setMenuState(null); // Zatvara meni ako uslovi nisu zadovoljeni
        }
      };
  
      return (
        <Box
          sx={{ display: 'flex' }}
          onMouseLeave={handleMouseLeave} // Proverava da li je miš napustio i dugme i meni
        >
          <Button
            color="inherit"
            sx={{ textTransform: 'none', fontSize: '20px' }}
            onMouseOver={handleMouseOver} // Otvori meni kada se pređe mišem
            fullWidth
          >
            {label}
          </Button>
  
          <Menu
            anchorEl={menuState}
            open={Boolean(menuState)}
            onClose={() => setMenuState(null)} // Osigurava da se meni zatvori kada se fokus izgubi
            MenuListProps={{
              onMouseLeave: handleMouseLeave, // Proverava da li je miš napustio meni
            }}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => setMenuState(null)} // Zatvara meni kada se izabere opcija
                component="a"
                href={item.href}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
    }
    return null;
  };
  

  return (
    <>
<AppBar position="fixed" color="primary">
  <Toolbar sx={{ height: "64px" }}>
    {/* Glavni Box sa dugmadima */}
    <Box sx={{ display: 'flex'}}>
  {/* Desktop Navbar */}
  <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '100%' }}>
    <Button color="inherit" href="/" sx={{ textTransform: 'none', fontSize: '20px' }}>
      {t('navbar.home')}
    </Button>

    {renderButtonWithMenu(
      PERMISSIONS.CREATE_NEW_READER,
      readersAnchorEl,
      setReadersAnchorEl,
      [
        { label: t('navbar.addReader'), href: '/new-reader' },
        { label: t('navbar.edit/delete'), href: '/readers' }
      ],
      t('navbar.readers')
    )}

    {renderButtonWithMenu(
      PERMISSIONS.CREATE_NEW_TICKET,
      newTicketAnchorEl,
      setNewTicketAnchorEl,
      [
        { label: t('navbar.newPlasticTicket'), href: '/new-ticket' },
        { label: t('navbar.newPaperTicket'), href: '/new-paper-ticket' }
      ],
      t('navbar.addTicket')
    )}

    {hasPermission(PERMISSIONS.CREATE_NEW_USER) && (
      <Box sx={{ display: 'flex' }}>
        <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/new-user">
          {t('navbar.addUser')}
        </Button>
      </Box>
    )}

    {hasPermission(PERMISSIONS.VIEW_MONITORING) && (
      <Box sx={{ display: 'flex' }}>
        <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/monitoring">
          {t('navbar.monitor')}
        </Button>
      </Box>
    )}

    {hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && (
      <Box sx={{ display: 'flex' }}>
        <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/access">
          {t('navbar.rights')}
        </Button>
      </Box>
    )}

    {renderButtonWithMenu(
      PERMISSIONS.VIEW_PRICELIST,
      priceListAnchorEl,
      setPriceListAnchorEl,
      [
        { label: t('navbar.addItem'), href: '/new-item' },
        { label: t('navbar.edit/delete'), href: '/price-list' },
        { label: t('navbar.addKind'), href: '/add-kind' },
        { label: t('navbar.addCategory'), href: '/add-category' }
      ],
      t('navbar.priceList')
    )}

    {/*hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && */(
      <Box sx={{ display: 'flex' }}>
        <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/purchasing">
          {t('navbar.purchasing')}
        </Button>
      </Box>
    )}  
  </Box>

  {/* Mobile Navbar */}
  <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
    <IconButton onClick={handleDrawerToggle} color="inherit">
      <MenuIcon />
    </IconButton>
  </Box>

  {/* Drawer for mobile */}
  <Drawer
    anchor="left"
    open={drawerOpen}
    onClose={handleDrawerToggle}
    sx={{
      '& .MuiDrawer-paper': {
        width: 250,
        padding: '20px',
      },
    }}
  >
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button color="inherit" href="/" sx={{ textTransform: 'none', fontSize: '20px' }}>
        {t('navbar.home')}
      </Button>

      {renderButtonWithMenu(
        PERMISSIONS.CREATE_NEW_READER,
        readersAnchorEl,
        setReadersAnchorEl,
        [
          { label: t('navbar.addReader'), href: '/new-reader' },
          { label: t('navbar.edit/delete'), href: '/readers' }
        ],
        t('navbar.readers')
      )}

      {renderButtonWithMenu(
        PERMISSIONS.CREATE_NEW_TICKET,
        newTicketAnchorEl,
        setNewTicketAnchorEl,
        [
          { label: t('navbar.newPlasticTicket'), href: '/new-ticket' },
          { label: t('navbar.newPaperTicket'), href: '/new-paper-ticket' }
        ],
        t('navbar.addTicket')
      )}

      {hasPermission(PERMISSIONS.CREATE_NEW_USER) && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/new-user">
            {t('navbar.addUser')}
          </Button>
        </Box>
      )}

      {hasPermission(PERMISSIONS.VIEW_MONITORING) && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/monitoring">
            {t('navbar.monitor')}
          </Button>
        </Box>
      )}

      {hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/access">
            {t('navbar.rights')}
          </Button>
        </Box>
      )}

      {renderButtonWithMenu(
        PERMISSIONS.VIEW_PRICELIST,
        priceListAnchorEl,
        setPriceListAnchorEl,
        [
          { label: t('navbar.addItem'), href: '/new-item' },
          { label: t('navbar.edit/delete'), href: '/price-list' },
          { label: t('navbar.addKind'), href: '/add-kind' },
          { label: t('navbar.addCategory'), href: '/add-category' }
        ],
        t('navbar.priceList')
      )}
      {/*hasPermission(PERMISSIONS.VIEW_ACCESS_RIGHTS) && */(
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button color="inherit" sx={{ textTransform: 'none', fontSize: '20px' }} href="/purchasing">
            {t('navbar.purchasing')}
          </Button>
        </Box>
      )}  
    </Box>
  </Drawer>
</Box>

    {/* Drugi Box sa ikonama */}
    <Box
  sx={{
    display: "flex",
    position: "fixed", // Koristimo 'fixed' da bude stalno u gornjem desnom kutu
    zIndex: 1000,
    right: 0,
    flexDirection: "row", // Dugmadi vertikalno
    gap: 1, // Razmak između dugmadi
    padding: 1.5, // Dodajte padding ako je potrebno
    justifySelf: "flex-end"
  }}
>
  <IconButton sx={{ color: "white" }} href="/change-password">
    <AccountCircleIcon />
  </IconButton>
  <DownloadApp />
  <LanguageSwitch />
  <Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
</Box>

  </Toolbar>
</AppBar>

{/* Spacer to prevent content overlap */}
<Toolbar />
    </>
  );
};

export default Navbar;
