import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteItem, deleteReader } from '../../api/axios';

const DataTable = ({ initialData, columns, entityName, h2 }) => {
  const { t } = useTranslation('global');
  const [data, setData] = useState(initialData);

  const navigate = useNavigate();

  const handleEdit = (id) => {
    navigate(`/${entityName}/edit/${id}`);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('table.areYouSure'))) {
      const params = new URLSearchParams();
      params.append("id", id);

      let result;
      if (entityName === "readers") {
        result = await deleteReader(params);
      } else if (entityName === "item") {
        result = await deleteItem(params);
      }
      console.log(result);
      if (result?.success) {
        // Filtriraj stavke kako bi uklonio obrisanu stavku
        setData((prevData) => prevData.filter((item) => item.id !== id));
        console.log(t('table.deletedSuccessfully'));
      } else {
        console.error(t('table.deleteFailed'));
      }
    }
  };

  return (
    <Box fullWidth>
      <Typography variant="h4" gutterBottom>
        {h2}
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: "transparent" }} fullWidth>
        <Table sx={{ backgroundColor: "transparent" }} fullWidth>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.accessor} variant="head">
                  {column.Header}
                </TableCell>
              ))}
              <TableCell variant="head">{t("table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {data.map((item) => (
    <TableRow key={item.id}>
      {columns.map((column) => (
        <TableCell key={column.accessor}>
          {column.accessor === "active" 
            ? item[column.accessor] === "1" 
              ? t("table.yes") 
              : t("table.no")
            : item[column.accessor]}
        </TableCell>
      ))}
      <TableCell>
        <IconButton
          color="inherit"
          onClick={() => handleEdit(item.id)}
          aria-label="edit"
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="inherit"
          onClick={() => handleDelete(item.id)}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ))}
</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTable;
