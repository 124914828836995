import React, { useEffect, useRef } from 'react';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

Chart.register(CategoryScale, LinearScale, BarController, BarElement, Title, Tooltip, Legend);

const ChartComponent = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const { t } = useTranslation("global");

  useEffect(() => {
    // Uništavanje prethodne instance grafikona ako postoji
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Formatiranje datuma u "DD-MM"
    const formattedLabels = data.labels.map((date) => {
      const options = { day: '2-digit', month: '2-digit' };
      return new Intl.DateTimeFormat('sr', options).format(new Date(date));
    });

    const ctx = chartRef.current.getContext('2d');
    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: formattedLabels, // Formatirani datumi
        datasets: [
          {
            label: t('reportSelection.soldTickets'),
            data: data.counts,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    // Uništavanje instance pri unmount-u
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, t]);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '300px' }}>
        <canvas ref={chartRef} style={{ maxWidth: '100%', maxHeight: '100%', padding: '16px'}}></canvas>
    </Box>);
};

export default ChartComponent;
