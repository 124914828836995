import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  FormControl,
  InputLabel
} from '@mui/material';
import { getPages, getPermisisons, getUsersExceptLogin, postRequest } from '../../api/axios';
import { getPermissions, getUsername } from '../../context/AuthFunc';
import { PERMISSION_ENDPOINT } from '../../constants/const';
import Error from '../Error/Error';
import Success from '../Success/Success';
import { useTranslation } from 'react-i18next';

const extractPageIdsFromToken = (permissions) => {
  return permissions.map(permission => parseInt(permission.split('_')[0]));
};

const AccessRights = () => {
  const { t } = useTranslation('global');

  const [users, setUsers] = useState([]);
  const [pages, setPages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [errMsg, setErrMsg] = useState('');
  const [succ, setSucc] = useState('');

  useEffect(() => {
    fetchUsers();
    setUserPermissions(getPermissions());
  }, []);

  useEffect(() => {
    fetchPages();
    if (selectedUser) {
      fetchUserPermissions(selectedUser.id);
    }
  }, [selectedUser]);

  const fetchUsers = async () => {
    const username = getUsername();
    const response = await getUsersExceptLogin(username);
    setUsers(response);
  };

  const fetchPages = async () => {
    const response = await getPages();
    const allowedPageIds = extractPageIdsFromToken(userPermissions);
    const filteredPages = response.filter(page => allowedPageIds.includes(parseInt(page.id)));
    setPages(filteredPages);
  };

  const fetchUserPermissions = async (user_id) => {
    const response = await getPermisisons(user_id);
    setPermissions(response);
  };

  const handlePermissionChange = async (pageId, permission, isChecked) => {
    const action = isChecked ? 'add' : 'remove';

    const formData = new URLSearchParams();
    formData.append('user_id', selectedUser.id);
    formData.append('page_id', pageId);
    formData.append('action', action);

    const response = await postRequest(PERMISSION_ENDPOINT, formData);

    if (response.success) {
      fetchUserPermissions(selectedUser.id);
      setSucc(t("authorization.success"));
      setErrMsg('');
    } else {
      setErrMsg(t("authorization.unsuccessful"));
    }
  };

  const handleUserChange = (e) => {
    const selectedUserId = e.target.value;
    const user = users.find(user => user.id === selectedUserId);
    if (user) {
      setSelectedUser(user);
    } else {
      console.error(t("authorization.selectedUserNotFound"));
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom sx={{ pt: 4, pl: 1 }}>
        {t("authorization.managingUserAccessLevel")}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel>{t("authorization.selectUser")}</InputLabel>
        <Select
          value={selectedUser ? selectedUser.id : ''}
          onChange={handleUserChange}
        >
          <MenuItem value="">
            <em>{t("authorization.selectUser")}</em>
          </MenuItem>
          {users.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {user.username}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedUser && (
        <Box>
          <Typography variant="h6" sx={{ pt: 4, pl: 1, mb: 2}}>
            {t("authorization.userAccessLevel")} {selectedUser.username}
          </Typography>
          <Paper elevation={3}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("authorization.page")}</TableCell>
                  <TableCell>{t("authorization.permission")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pages.map(page => (
                  <TableRow key={page.id}>
                    <TableCell>{page.name}</TableCell>
                    <TableCell>
                      <Checkbox
                        checked={permissions.some(p => p.page_id === page.id)}
                        onChange={(e) =>
                          handlePermissionChange(page.id, 'view', e.target.checked)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      )}
      {errMsg && <Error message={errMsg} onClose={() => setErrMsg('')} />}
      {succ && <Success message={succ} onClose={() => setSucc('')} />}
    </Box>
  );
};

export default AccessRights;


{/*                <td>
                    <input
                      type="checkbox"
                      checked={permissions.some(p => p.page_id === page.id && p.permission === 'view')}
                      onChange={(e) => handlePermissionChange(page.id, 'view', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions.some(p => p.page_id === page.id && p.permission === 'edit')}
                      onChange={(e) => handlePermissionChange(page.id, 'edit', e.target.checked)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions.some(p => p.page_id === page.id && p.permission === 'create')}
                      onChange={(e) => handlePermissionChange(page.id, 'create', e.target.checked)}
                    />
                  </td> */}